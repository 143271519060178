import React from 'react';
import Navigation from "../components/includes/Navigation";
import {Parallax, ParallaxLayer} from '@react-spring/parallax'

import iss from '../../styles/img/header/iss.png'
import skyCastle from '../../styles/img/header/skyCastle.png'
import background from '../../styles/img/header/background.png'


const Home = () => {
    return (
        <>
            <Parallax pages={5}>
                <img src={background} className={'absolute'} alt={'principal background'}/>
                <ParallaxLayer offset={0}>
                    <Navigation/>
                </ParallaxLayer>

                <ParallaxLayer offset={0.4} speed={1}>
                    <h1 className={'text-9xl text-white text-center'}>ROLEPLAY - AI</h1>
                </ParallaxLayer>

                <ParallaxLayer offset={1} speed={0.5} className={'flex justify-center'}>
                    <div className={'flex flex-col items-center gap-24 bg-stone-800 self-center p-5'}>
                        <h2 className={'text-6xl text-white text-center'}>C'est quoi ? </h2>

                        <p className={'text-center text-white'}>POLOLOLO</p>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer offset={2} speed={0.75} className={'flex justify-start'}>
                    <div className={'flex flex-col justify-around gap-24 bg-stone-800 self-center p-5'}>
                        <h2 className={'text-6xl text-white text-center'}>C'est quoi ? </h2>

                        <p className={'text-center text-white'}>POLOLOLO</p>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={2} speed={0.75} className={'flex justify-end'}>
                    <img src={iss} alt={'iss display'}/>
                </ParallaxLayer>

                <ParallaxLayer offset={3} speed={0.5}>
                    <img src={skyCastle} alt={'skyCastle display'}/>
                </ParallaxLayer>

                <ParallaxLayer offset={3} speed={0.5} className={'flex justify-end'}>
                    <div className={'flex flex-col justify-around gap-24 bg-stone-800 self-center p-5'}>
                        <h2 className={'text-6xl text-white text-center'}>C'est quoi ? </h2>

                        <p className={'text-center text-white'}>POLOLOLO</p>
                    </div>
                </ParallaxLayer>
            </Parallax>
        </>
    );
};

export default Home;
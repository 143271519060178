import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Button from "./utils/Button";

const Registration = () => {

    const navigation = useNavigate();

    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`${process.env.REACT_APP_API_URL}/register`, {
            email,
            username,
            password,
            confirmPassword
        }, {
            headers: {
                'Authorization': `${process.env.REACT_APP_API_TOKEN}`,
                'Accept':`application/vnd.api+json`,
                'Content-Type':`application/vnd.api+json`
            }
        }).then((response) => {
            console.log(response)
            //localStorage.setItem('userToken', res.data.token);
            //navigation('/');
        }).catch((error) => {
            console.log(error)
        }).finally(() => {

        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4 grid grid-cols-2 gap-3">
                <div>
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="email">E-mail</label>
                    <input onChange={(e) => setEmail(e.target.value)} type="email"
                           placeholder="Email" id="email"
                           className="w-full px-3 py-2 border border-neutral-700 rounded-md bg-neutral-700 text-white focus:outline-none focus:border-indigo-500"
                           required/>
                </div>
                <div>
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="username">Nom
                        d'utilisateur</label>
                    <input onChange={(e) => setUsername(e.target.value)} type="username"
                           placeholder="Nom d'utilisateur" id="username"
                           className="w-full px-3 py-2 border border-neutral-700 rounded-md bg-neutral-700 text-white focus:outline-none focus:border-indigo-500"
                           required/>
                </div>
            </div>
            <div className="mb-4">
                <label className="block text-white text-sm font-bold mb-2" htmlFor="password">Mot de passe</label>
                <input onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Mot de passe"
                       id="password"
                       className="w-full px-3 py-2 border border-neutral-700 rounded-md bg-neutral-700 text-white focus:outline-none focus:border-indigo-500"
                       required/>
            </div>
            <div className="mb-4">
                <label className="block text-white text-sm font-bold mb-2" htmlFor="confirmPassword">Confirmez votre Mot de passe</label>
                <input onChange={(e) => setConfirmPassword(e.target.value)} type="password" placeholder="Confirmez votre Mot de passe"
                       id="confirmPassword"
                       className="mb-6 w-full px-3 py-2 border border-neutral-700 rounded-md bg-neutral-700 text-white focus:outline-none focus:border-indigo-500"
                       required/>
            </div>
            <Button type={'submit'} content={'S\'inscrire'}/>
        </form>
    );
};

export default Registration;
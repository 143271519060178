import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Button from "./utils/Button";

const Connection = () => {

    const navigation = useNavigate();

    const [name, setName] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`${process.env.REACT_APP_API_URL}/login`, {
            name,
            password
        }).then((response) => {
            console.log(response)
            //localStorage.setItem('userToken', res.data.token);
            //navigation('/');
        }).catch((error) => {
            console.log(error)
        }).finally(() => {

        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label className="block text-white text-sm font-bold mb-2" htmlFor="username">Nom
                    d'utilisateur</label>
                <input onChange={(e) => setName(e.target.value)} type="username"
                       placeholder="Nom d'utilisateur" id="username"
                       className="w-full px-3 py-2 border border-neutral-700 rounded-md bg-neutral-700 text-white focus:outline-none focus:border-indigo-500"
                       required/>
            </div>
            <div className="mb-4">
                <label className="block text-white text-sm font-bold mb-2" htmlFor="password">Mot de passe</label>
                <input onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Mot de passe"
                       id="password"
                       className="mb-6 w-full px-3 py-2 border border-neutral-700 rounded-md bg-neutral-700 text-white focus:outline-none focus:border-indigo-500"
                       required/>
            </div>
            <Button type={'submit'} content={'Connexion'}/>
        </form>
    );
};

export default Connection;
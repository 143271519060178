import React from 'react';

const Button = (props) => {
    return (
        <button
            className="w-full bg-indigo-500 text-white text-sm font-bold py-2 px-4 rounded-md hover:bg-indigo-600 transition duration-300"
            type={props.type}>{props.content}
        </button>
    );
};

export default Button;
import React from 'react';
import {Route, Routes} from 'react-router-dom';

/* GUEST AREA */
import GuestHome from "./guest_area/pages/Home";
import Authentication from "./guest_area/pages/Authentication";

/* PLAYER AREA */
import PlayerHome from "./player_area/pages/Home";
import Profile from "./player_area/pages/account/Profile";

/* SECURITY ROUTE */
import ProtectedRoutes from "./security/ProtectedRoutes";
import PublicRoutes from "./security/PublicRoutes";

function App() {
    return (
        <>
            <Routes>
                <Route path={"/player"} element={<ProtectedRoutes/>}>
                    <Route path={"/player"} element={<PlayerHome/>}/>
                    <Route path={"/player/profile"} element={<Profile/>}/>
                </Route>

                <Route path={"/"} element={<PublicRoutes/>}>
                    <Route path={"/"} element={<GuestHome/>}/>
                    <Route path={"/authentication"} element={<Authentication/>}/>
                </Route>
            </Routes>
        </>
    );
}

export default App;

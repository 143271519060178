import React from 'react';
import {Link} from "react-router-dom";
import Button from "../utils/Button";

const Navigation = () => {
    return (
        <nav className="w-full z-20 top-0 left-0 bg-stone-800 bg-opacity-50">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <div className="flex md:order-2">
                    <Link to={'/authentication'} className={'text-white'}>
                        <Button content={'Authentification'}/>
                    </Link>
                </div>
                <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1">
                    <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium rounded-lg md:flex-row md:space-x-8 md:mt-0">
                        <li>
                            <a href="#" className="block py-2 pl-3 pr-4 text-white">Home</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 pl-3 pr-4 text-white">About</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 pl-3 pr-4 text-white">Services</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 pl-3 pr-4 text-white">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
import React, {useState} from 'react';

import Connection from "../components/Connection";
import Registration from "../components/Registration";
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'

const Authentication = () => {

    const [registration, setRegistration] = useState(false);

    return (
        <>

            <h1 className={'text-white text-5xl text-center my-16'}>{registration ? 'Inscription' : 'Connexion'}</h1>
            <div className={'grid grid-cols-3 gap'}>
                <div>
                    {/*TODO image dynamique*/}
                </div>
                <div>
                    {registration ?
                        <Registration/>
                        :
                        <Connection/>
                    }
                    <div className={'flex justify-between mt-5'}>
                        <Link to={'/'} className={'text-white hover:text-indigo-500 font-bold'}>
                                <span className={'mr-2'}>
                                    <FontAwesomeIcon icon={faArrowLeft}/>
                                </span>
                            Retour au site
                        </Link>
                        <div className={'text-center text-white'}>
                            <span>{registration ? "Déjà un compte ? " : "Pas de compte ? "}</span>
                            <span className={'font-bold hover:text-indigo-500 cursor-pointer'}
                               onClick={() => registration ? setRegistration(false) : setRegistration(true)}>
                                {registration ? "Se connecter" : "S'inscrire"}
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    {/*TODO image dynamique*/}
                </div>
            </div>
        </>
    )
        ;
};

export default Authentication;